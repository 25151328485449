<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-6 c g text-center">
            <h4>
                <h4>
                    <img :src="require('@/assets/images/banner.png')" style="width: 150px; cursor: pointer;" @click="goHome()">
                    &nbsp;
                    &mdash;
                    &nbsp;
                    <img :src="`https://ui-avatars.com/api/?name=${encodeURI(user.name)}&rounded=true&background=random`" style="width: 30px" alt=""> {{ user.name }}
                </h4>
            </h4>
        </div>
        <div class="col-12"></div>
        <div class="col-12 col-lg-3 g">
            <div class="row">
                <div class="col-lg-11 col-8 c">
                    <div class="row">
                        <div class="col-12 col-lg-8" style="padding: 2px">
                            <button class="btn btn-sm btn-primary btn-block" title="رسالة جديدة" v-b-modal.modal-message @click="n_message = ''; to = '';"><i class="fa fa-plus"></i> رسالة جديدة</button>
                        </div>
                        <div class="col-12 col-lg-4" style="padding: 2px">
                            <button class="btn btn-sm btn-secondary btn-block" title="كتم الصوت" v-if="!muted" @click="muted = true"><i class="fa fa-volume-off"></i> كتم</button>
                            <button class="btn btn-sm btn-success btn-block" title="تشغيل الصوت" v-if="muted" @click="muted = false"><i class="fa fa-volume-up"></i> تشغيل</button>
                        </div>
                        <div class="col-12 g"></div>
                    </div>
                </div>
            </div>
            <div class="card card-body chats">
                <div class="col-12 text-center g" v-if="chats.length == 0">
                    <strong class="text-danger fadefade">في انتظار المحادثات...</strong>
                </div>
                <div v-if="isTop">
                    <div class="strike text-muted">
                        <span>المحادثات المثبتة</span>
                    </div>
                </div>
                <template v-for="chat in chats">
                <div class="chat" v-if="chat.type != 'hr'" :key="chat.user" @click="openChat(chat)" :class="(chat.user == current.user ? 'active' : (chat.unreadCount > 0 ? 'alert' : '')) + ' ' + (chat.top ? 'top' : '')">
                    <div :class="'media ' + (chat.locked ? 'locked' : '')">
                        <a class="d-flex" href="javascript:;">
                            <img :src="chat.locked ? require('@/assets/images/avatars/locked.png') : (chat.avatar ? chat.avatar : require('@/assets/images/avatars/avatar2.png'))" class="avatar">
                        </a>
                        <div class="media-body">
                            <span class="chat-title">
                                <span class="fa fa-thumb-tack text-danger" v-if="chat.pinned"></span>
                                <template v-for="tag in chat.tags">
                                    <span v-if="tags_obj[tag]" :key="tag" :style="`color: ${tags_obj[tag].color}; padding-left: 5px`" :title="tags_obj[tag].title">
                                        <i :class="`fa fa-${tags_obj[tag].icon}`"></i>
                                    </span>
                                </template>
                                {{ chat.title }}
                                <br>
                                <span style="color: #999" class="lastmsg">
                                    <strong v-if="chat.lastMessage.fromme">أنت:</strong>
                                    <span v-if="chat.lastMessage.fromme">
                                        <span v-if="chat.lastMessage.ack == 1" class="text-muted">
                                            <i class="fa fa-check text-muted"></i>
                                        </span>
                                        <span v-if="chat.lastMessage.ack == 2" class="text-muted">
                                            <i class="fa fa-check text-muted"></i>
                                            <i class="fa fa-check text-muted" style="position: relative; right: -7px"></i>
                                        </span>
                                        <span v-if="chat.lastMessage.ack == 3">
                                            <i class="fa fa-check text-info"></i>
                                            <i class="fa fa-check text-info" style="position: relative; right: -7px"></i>
                                        </span>
                                    </span>
                                    <strong v-if="chat.lastMessage.type != 'chat'" v-html="chat.lastMessage.type.replace('ptt', '<i class=\'fa fa-microphone\'></i> رسالة صوتية').replace('revoked', '<i class=\'fa fa-comment\'></i>').replace('image', '<i class=\'fa fa-image\'></i> صورة')"></strong>
                                    {{ chat.lastMessage.body }}
                                </span>
                                <div v-if="chat.active_users">
                                    <div v-if="chat.active_users.length">
                                        <span v-for="u in chat.active_users" :key="u.name" class="g">
                                            <img :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" width="20" height="20">
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="chat.type == 'hr'" :key="chat.user">
                    <div class="strike text-muted">
                        <span>باقي المحادثات</span>
                    </div>
                </div>
                </template>
            </div>
            <div class="row">
                <div class="col-2 col-lg-2" v-for="u in active_users" :key="u.title">
                    <b-avatar badge badge-variant="success" :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" style="width: 30px !important; height: 30px !important"></b-avatar>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-9 g messages-container" v-if="current.user">
            <div class="card">
                <div class="card-header">
                    <div class="media">
                        <a class="d-flex" href="javascript:;">
                            <img :src="current.avatar ? current.avatar : require('@/assets/images/avatars/avatar2.png')" class="avatar" v-b-modal.modal-pic>
                        </a>
                        <div class="media-body">
                            <span class="chat-title">
                                <span v-if="allowToShowNumber()">
                                    {{ current.title }}
                                </span>
                                <span v-if="!allowToShowNumber()">
                                    {{ current.title.substring(0,8) }}****
                                </span>
                                <br>
                                <span style="color: #999" class="lastmsg" v-if="allowToShowNumber()">
                                    {{ current.user }}
                                </span>
                                <span style="color: #999" class="lastmsg" v-if="!allowToShowNumber()">
                                    {{ current.user.substring(0,8) }}****
                                </span>
                                <span v-if="current.locked">
                                    <i class="fa fa-lock"></i> المحادثة مقفلة بواسطة {{ current.locked.by }}
                                </span>
                                <div v-if="current.active_users">
                                    <div v-if="current.active_users.length">
                                        <span v-for="u in current.active_users" :key="u.name" class="g">
                                            <img :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" width="20" height="20">
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-sm btn-outline-danger fa fa-thumb-tack" title="الغاء تثبيت المحادثة" v-if="current.pinned" @click="unpin()"></button>
                        <button class="btn btn-sm btn-info fa fa-thumb-tack" title="تثبيت المحادثة" v-if="!current.pinned" @click="pin()"></button>
                        &nbsp;
                        <button class="btn btn-sm btn-success fa fa-unlock" title="الغاء قفل المحادثة" v-if="current.locked" @click="unlock()"></button>
                        <button class="btn btn-sm btn-secondary fa fa-lock" title="قفل المحادثة" v-if="!current.locked" @click="lock()"></button>
                        &nbsp;
                        <b-dropdown
                            id="dropdown-1" style="border-radius: 0px"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="🔖"
                            variant="primary" dropleft
                            size="sm"
                        >
                            <b-dropdown-item v-for="tag in tags" :key="tag._id" @click="doTag(tag._id)" :class="current.tags.includes(tag._id) ? 'active-tag' : ''">
                                <span :style="`color: ${tag.color};`">
                                    <i :class="`fa fa-${tag.icon}`"></i>
                                    {{ tag.name }}
                                </span>
                            </b-dropdown-item>
                        </b-dropdown>
                        &nbsp;
                        <b-dropdown @click="n_note = ''"
                            id="dropdown-1" style="border-radius: 0px"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="✏️"
                            variant="danger" dropleft
                            size="sm"
                        >
                            <input type="text" placeholder="اكتب ملاحظة..." v-model="n_note" style="height: 30px; border: 2px solid #ddd" id="note">
                            <b-dropdown-item v-for="(note, ii) in current.notes" :key="ii">
                                <span>
                                    <img :src="`https://ui-avatars.com/api/?name=${encodeURI(note.by)}&rounded=true&background=random`" style="width: 18px; height: 18px;" alt="">
                                    {{ note.body }}
                                    <br><small class="text-muted">{{ note.by }} ~ {{ note.date.replace("T", ' ').split('.')[0] }}</small>
                                </span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="card-body messages border" id="messages" style=" position: relative;">
                    <div class="row">
                        <div class="col-12 g"></div>
                        <template v-for="(message, i) in messages">
                            <div class="col-12 col-lg-5" :key="i + 11111" v-if="!message.fromMe"></div>
                            <div class="col-12 col-lg-7" :key="i">
                               <div :class="'alert g message ' + (message.fromMe ? 'bg-light-success' : 'bg-light-secondary')">
                                <div v-if="message.quotedMsg">
                                <div v-if="message.quotedMsg.type">
                                    <small v-if="message.quotedMsg.type != 'chat'">
                                    <i class="fa fa-arrow-left"></i> رد على:
                                    {{ message.quotedMsg.type }}
                                    </small>
                                    <small v-if="message.quotedMsg.type == 'chat'">
                                    <i class="fa fa-arrow-left"></i> رد على: "{{
                                        message.quotedMsg.body
                                    }}"
                                    </small>
                                </div>
                                </div>
                                <div v-if="message.isForwarded">
                                <span><i class="fa fa-reply"></i> رسالة محولة</span>
                                </div>
                                <span class="text-dark" v-if="message.body != 'undefined'">{{ message.body }}</span>
                                <span
                                v-if="message.hasMedia"
                                v-b-modal.modal-1
                                @click="
                                    type = message.type;
                                    messageMedia = {};
                                    getMedia(current.user, message.mediaKey);
                                "
                                style="cursor: pointer"
                                >
                                <br />
                                <img
                                    v-if="
                                    message.type != 'ptt' &&
                                    (message.type == 'document' ||
                                        message.type == 'pdf' ||
                                        message.type == 'image' ||
                                        message.type == 'video' ||
                                        message.type == 'audio')
                                    "
                                    :src="require('@/assets/images/' + message.type + '.png')"
                                    style="width: 30px"
                                />
                                <img
                                    v-if="message.type == 'ptt'"
                                    :src="require('@/assets/images/ppt.png')"
                                    style="width: 30px"
                                />
                                <span v-if="message.type != 'ptt'">تشغيل/عرض</span>
                                <span v-if="message.type == 'ptt'">تشغيل/عرض</span>
                                </span>
                                <br />
                                <small>{{ message.time }}
                                    <span v-if="message.fromMe">
                                        <span v-if="message.ack == 1" class="text-muted">
                                            <i class="fa fa-check text-muted"></i>
                                        </span>
                                        <span v-if="message.ack == 2" class="text-muted">
                                            <i class="fa fa-check text-muted"></i>
                                            <i class="fa fa-check text-muted" style="position: relative; right: -7px"></i>
                                        </span>
                                        <span v-if="message.ack == 3">
                                            <i class="fa fa-check text-info"></i>
                                            <i class="fa fa-check text-info" style="position: relative; right: -7px"></i>
                                        </span>
                                    </span>
                                </small>
                               </div>
                            </div>
                            <div class="col-12" :key="i + 100"></div>
                        </template>
                    </div>
                </div>
                <div class="card-footer" style=" white-space: nowrap; position: relative;">
                    <div class="templates" v-if="message == '/' || message == '\\'">
                        <div class="card card-body" style="padding-bottom: 0px;">
                            <div class="row">
                                <div class="col-12 text-center g text-danger" v-if="templates.length == 0">
                                    لا يوجد اي ردود جاهزة.
                                </div>
                                <div class="col-12 g tem" @click="message = template.body; send();" v-for="template in templates" :key="template._id">
                                    {{ template.body }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="file" :id="'file'" style="display:none;">
                        <input type="text" class="form-control" v-model="message" @keypress="typing()" placeholder="اكتب هنا..." id="message">
                        <span class="input-group-text btn btn-info" style="border-radius: 0px" @click="record()" v-b-modal.modal-2><i class="fa fa-microphone"></i></span>
                        <span class="input-group-text btn btn-default" style="border-radius: 0px" @click="openFile()"><i class="fa fa-paperclip"></i></span>
                        <span class="input-group-text btn btn-success" style="border-radius: 5px 0px 0px 5px" @click="send()"><i class="fa fa-paper-plane"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-2" title="تسجيل صوتي" hide-footer>
    <div class="col-12 text-center">
      <audio-recorder
        upload-url="some url"
        :attempts="1"
        :time="30"
        :before-recording="callback"
        :after-recording="callback"
        :before-upload="callback"
        :successful-upload="callback"
        :failed-upload="callback"/>
        <br><br>
    <button class="btn btn-success" v-if="audioChunks" @click="sendVoice()"><i class="fa fa-paper-plane"></i> ارسال الآن</button>
    </div> 
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-message" title="ارسال رسالة جديدة" hide-footer>
        <div class="form-group">
          <label for="">رقم الهاتف (بالصيغة الدولية)</label>
          <input type="text"
            class="form-control form-control-lg" @keyup="checkNumber()" v-model="to" :style="'text-align: center; ' + (error_check_number ? 'border: solid 2px red;' : 'border: solid 2px green;')" placeholder="+9665...." dir="ltr">
        </div>
        <div class="col-12 text-center text-danger" v-if="error_check_number">
            {{ error_check_number }}
            <br>
        </div>
        <div class="col-12 text-center text-success" v-if="!error_check_number && to">
            <strong>
                <i class="fa fa-check"></i>
                لديه واتساب
            </strong>
        </div>
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="n_message" placeholder="الرسالة هنا..." rows="3"></textarea>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-success disabled" disabled v-if="error_check_number || !to || !n_message || n_message == '' || to == '' || n_message == ' '">
                ارسال الآن
            </button>
            <button class="btn btn-success" v-if="!error_check_number && to && n_message" @click="sendMessageToNew()">
                ارسال الآن
            </button>
        </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-pic" :title="'صورة الملف الشخصي'" hide-footer>
        <img :src="current.avatar ? current.avatar : require('@/assets/images/avatars/avatar2.png')" style="width: 100% !important; border-radius: 5px">
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-1" size="lg" title="تشغيل المحتوى" hide-footer>
      <div class="col-12 text-center">
        <div class="c col-12 text-center" v-if="loading2">
          <img :src="require('@/assets/images/loading.svg')" /><br />
          جاري تشغيل/عرض المحتوى...<br /><br /><br />
        </div>
        <div v-if="!loading2">
          <span v-if="type == 'image'">
            <img
              :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
              style="width: 100%"
            />
          </span>
          <span v-if="type == 'document'">
            <a
              target="_blank"
              class="btn btn-lg btn-success"
              :href="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
            >
              <i class="fa fa-file"></i>
              عرض الملف</a
            >
          </span>
          <span v-if="type == 'document'">
            <br><br>
            <a
              target="_blank"
              class="btn btn-lg btn-info"
              download=""
              :href="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
            >
              <i class="fa fa-download"></i>
              تحميل الملف</a
            >
          </span>
          <span v-if="type == 'audio' || type == 'ptt'">
            <audio controls>
              <source
                :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                :type="messageMedia.mimetype"
              />
            </audio>
          </span>
          <span v-if="type == 'video'">
            <video width="320" height="240" controls>
              <source
                :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                :type="messageMedia.mimetype"
              />
            </video>
          </span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BModal, VBModal } from "bootstrap-vue";
import { BDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BModal,
    BDropdown,
    BDropdownItem,
    BAvatar
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            loading2: false,
            audio: null,
            chats: [],
            current: {title:""},
            messages: [],
            muted: false,
            messageMedia: {},
            message: "",
            type: "",
            started: false,
            done: false,
            audioChunks: "",
            isTop: false,
            n_note: "",
            tags: [],
            tags_obj: {},
            n_message: "",
            to: "",
            error_check_number: false,
            active_users: [],
            templates: [],
            voices: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/tags/list', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.tags = r.response
                g.tags.forEach(function(a){
                    g.tags_obj[a._id] = a
                })
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/user/templates/list', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.templates = r.response
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $(document).keyup(function(e) {
            if (e.keyCode == 27) { // Esc
                g.current = {}
            }
            if (e.keyCode == 13 && $("#message").is(":focus")) { // Enter
                g.send()
            }
            if (e.keyCode == 13 && $("#note").is(":focus")) { // Note
                g.sendNote()
            }
        });
        if(!window.location.hash){
            window.location.hash = "_"
            location.reload()
        }
        var x = setInterval(() => {
            if($("#file").length){
                clearInterval(x)
                $("#file").on("change", async function(){
                    var input = $("#file")[0]
                    g.$socket.emit("send", {
                        to: g.current._id,
                        type: "file",
                        body: await g.getBase64(input.files[0]),
                        mimetype: input.files[0].type
                    })
                    $("#file").val("")
                    g.messages.push({
                        body: "جاري الارسال...",
                        fromMe: true
                    })
                    $('#messages').animate({ scrollTop: 99999999 }, );
                    setTimeout(() => {
                        g.refresh()
                    }, 1000)
                })
            }
        }, 10);
        g.sockets.subscribe("active_users", function(data){
            g.active_users = data
        })
        g.sockets.subscribe("message", function (data) {
            setTimeout(() => {
                if(g.audio){
                    g.audio.pause();
                }
                if(!g.muted){
                    g.audio = new Audio(require("@/assets/sounds/new_message.mp3"));
                    g.audio.play();
                }
                g.$socket.emit("request-chats", "")
                if(g.current.user){
                    g.$socket.emit("request-messages", g.current.user)
                }
            }, 100);
        })
        setTimeout(() => {
            if(g.chats.length == 0){
                location.reload()
            }
        }, 5000);
        setInterval(() => {
            if($(".fadefade").length){
                if($(".fadefade").is(":visible")){
                    $(".fadefade").fadeOut('slow');
                }else{
                    $(".fadefade").fadeIn('slow');}
            }
        }, 500);
        Array.prototype.insert = function ( index, ...items ) {
            this.splice( index, 0, ...items );
        };
        g.sockets.subscribe("chats", function (data) {
            g.chats = data
            g.chats.sort(function(a, b){
                if(a.top < b.top) { return 1; }
                if(a.top > b.top) { return -1; }
                return 0;
            })
            var c = 0, i = 0;
            g.chats.forEach(function(a){
                i++
                if(a.user == g.current?.user){
                    g.current = a
                }
                if(a.top){
                    c = i;
                }
            })
            if(c != 0){
                g.chats.insert(c, {
                    type: 'hr'
                });
                g.isTop = true
            }else{
                g.isTop = false
            }
        })
        g.sockets.subscribe("messages", function (data) {
            g.messages = data
        })
        g.$socket.emit("request-chats", "")
        setTimeout(() => {
            g.$socket.emit("request-chats", "")
        }, 3000);
        g.sockets.subscribe("messageMedia", function (data) {
            g.messageMedia = data;
            g.loading2 = false;
        })
        setInterval(() => {
            g.refresh()
        }, 2000);
        setInterval(() => {
            if($('[d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"]').is(":visible")){
                $('.ar-recorder__stop').fadeIn(0)
                $('[d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"]').parent().parent().html("<h1><i class='fa fa-times text-white'></i></h1>")
                $(".ar-recorder__stop").html("<h1><i class='fa fa-paper-plane text-success'></i></h1>")
            }
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-recorder__records-limit").remove()
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-recorder__time-limit").remove()
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-player").remove()
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-records").remove()
        }, 10);
    },
    methods: {
        allowToShowNumber(){
            if(!this.user.admin_id || this.user?.pers?.includes('show-numbers') || this.user?.pers?.includes('*')){
                return true;
            }else{
                return false;
            }
        },
        goHome(){
            window.location = '/'
        },
        openFile(){
            var g = this;
            var current = g.current
            $("#file").click()
        },
        getMedia(user, mediaKey) {
            var g = this;
            g.loading2 = true;
            g.$socket.emit("request-message-media", {
                user: user,
                key: mediaKey
            })
        },
        callback (data) { var g = this;
            if(data.blob){
                g.audioChunks = data.blob
            }
        },
        send(){
            var g = this;
            if(this.message?.trim() != ""){
                g.$socket.emit("send", {
                    to: g.current._id,
                    type: "text",
                    body: g.message
                })
                g.messages.push({
                    body: "جاري الارسال...",
                    fromMe: true
                })
                $('#messages').animate({ scrollTop: 99999999 }, );
                setTimeout(() => {
                    g.refresh()
                }, 1000);
                g.message = "";
            }
        },
        getBase64(file) {
            return new Promise((resolveOuter) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolveOuter(reader.result.split("base64,")[1])
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            })
        },
        openChat(chat){
            var g = this;
            this.current = chat;
            g.messages = []
            g.$socket.emit("request-messages", chat.user)
            $("#message").focus()
            setTimeout(() => {
                $("#message").focus()
                $('#messages').animate({ scrollTop: 99999999 }, );
                setTimeout(() => {
                    $("#message").focus()
                    $('#messages').animate({ scrollTop: 99999999 }, );
                }, 500);
            }, 100);
        },
        refresh(){
            var g = this;
            g.$socket.emit("request-chats", "")
            if(g.current.user){
                g.$socket.emit("request-messages", g.current.user)
            }
        },
        checkNumber(){
            var g = this;
            g.$socket.emit("request-check-number", g.to)
            g.sockets.subscribe("check-number", function (data) {
                g.error_check_number = data;
            })
        },
        record(){
            this.audioChunks = null;
        },
        sendVoice(){
            var g = this;
            var reader = new FileReader();
            reader.readAsDataURL(g.audioChunks); 
            reader.onloadend = async function() {
                var base64data = reader.result;       
                g.$socket.emit("send", {
                    to: g.current._id,
                    type: "file",
                    body: base64data.split("base64,")[1],
                    mimetype: "audio/mp3"
                })
                g.messages.push({
                    body: "جاري الارسال...",
                    fromMe: true
                })
                $("#modal-2___BV_modal_header_ > button").click()
                $('#messages').animate({ scrollTop: 99999999 }, );
                setTimeout(() => {
                    g.refresh()
                })
            }
        },
        pin(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري تثبيت المحادثة...",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
            this.$socket.emit("pin-chat", g.current._id)
                g.current.pinned = true;
                g.current.top = true;
                g.hotrefresh()
        },
        unpin(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري الغاء تثبيت المحادثة...",
                    icon: "EditIcon",
                    variant: "danger",
                  },})
            this.$socket.emit("unpin-chat", g.current._id)
                g.current.pinned = false;
                g.current.top = false;
                g.hotrefresh()
        },
        sendNote(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري اضافة الملاحظة...",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.$socket.emit("note", {
                    user: g.current._id,
                    body: g.n_note
                })
                g.n_note = ""
                g.hotrefresh()
        },
        lock(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري قفل المحادثة...",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
            this.$socket.emit("lock", g.current._id)
            g.current.locked = {
                by: g.user.socket_id
            }
                g.hotrefresh()
        },
        unlock(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري الغاء قفل المحادثة...",
                    icon: "EditIcon",
                    variant: "danger",
                  },})
            this.$socket.emit("unlock", g.current._id)
            g.current.locked = undefined;
                g.hotrefresh()
        },
        doTag(id){
            var g = this;
            if(g.current.tags.includes(id)){
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري ازالة التصنيف...",
                    icon: "EditIcon",
                    variant: "danger",
                  },})
                this.$socket.emit("remove-tag", {
                    user: g.current._id,
                    tag: id
                })
            }else{
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري اضافة التصنيف...",
                    icon: "EditIcon",
                    variant: "success",
                  },})
                this.$socket.emit("add-tag", {
                    user: g.current._id,
                    tag: id
                })
            }
                g.hotrefresh();
        },
        typing(){
            var g = this;
            this.$socket.emit("typing", g.current._id)
        },
        hotrefresh(){
            var g = this;
            setTimeout(() => {
                g.refresh()
            }, 100);
        },
        sendMessageToNew(){
            var g = this;
            var number = g.to;
            number = number.replace(" ", "")
            number = number.replace("+", "")
            number = number?.replace(/\D/g, '') + "@c.us"
            g.$socket.emit("send", {
                to: number,
                type: "text",
                body: g.n_message
            })
            setTimeout(() => {
                $("#modal-message___BV_modal_header_ > button").click()
            }, 1000);
            setTimeout(() => {
                if(g.chats[0]){
                    if(g.chats[0]?.user?.includes(number.substring(5,11))){
                        g.openChat(g.chats[0])
                    }else{
                        setTimeout(() => {
                            if(g.chats[0]){
                                if(g.chats[0]?.user?.includes(number.substring(5,11))){
                                    g.openChat(g.chats[0])
                                }
                            }
                        }, 3000);
                    }
                }else{
                    setTimeout(() => {
                        if(g.chats[0]){
                            if(g.chats[0]?.user?.includes(number.substring(5,11))){
                                g.openChat(g.chats[0])
                            }
                        }
                    }, 3000);
                }
            }, 1000);
        }
    }
}
</script>

<style>
.chats{
    height: 80vh !important;
    overflow-y: scroll;
}
.chat{
    background: #fafafa;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
}
.chat-title{
    position: relative;
    left: 10px;
    top: 5px;
}
.chat.active{
    border-bottom: solid 2px #64CCC5 !important;
    background: #F0F0F0 !important;
}
.chat:hover{
    background: #eee !important;
    cursor: pointer;
}
.chat .media{
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.chat.active{
    background: #fefefe;
}
.chat.alert .media{
    border-right: solid 3px #FF7676
}
.chat.top .media{
    border-left: solid 3px #98E4FF;
    padding-left: 3px;
}
.lastmsg{
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.avatar{
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
}
.messages-container{
    height: 80vh !important;
}
.messages{
    height: 68vh;
    overflow-y: scroll;
}
.message{
    white-space: pre-line; 
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #eee;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}
.active-tag{
    background: #eee !important;
}
.locked *{
    color: #D0D4CA !important;
}
@media (max-width : 420px) {
    .modal{
        z-index: 99999 !important;
    }
}
.templates{
    position: absolute;
    bottom: 101px;
    z-index: 99999 !important;
    border: 2px solid #86A789;
    background: #EBF3E8;
    width: 95%;
}
.templates .card{
    background: #EBF3E8;
}
.tem{
    background: #eee;
    cursor: pointer;
    margin-top: 3px;
}
.tem:hover{
    background: #dae3d7 !important;
    cursor: pointer;
}
</style>